import React from "react";
import "./Contact.css";
const Contact = () => {
  //   const form = useRef();
  //   const [done, setDone] = useState(false);
  //   const [val, setVal] = useState();
  //   const [mail, setMail] = useState();
  //   const [msg, setMsg] = useState();

  //   const sendEmail = (e) => {
  //     e.preventDefault();

  //     emailjs
  //       .sendForm(
  //         "service_p2cq0mp", // service id
  //         "template_emisku8", // template id
  //         form.current,
  //         "5T-wmFF0RpsKV2uPr" // public key
  //       )
  //       .then(
  //         (result) => {
  //           console.log(result.text);
  //           setDone(true);
  //           setVal("");
  //           setMail("");
  //           setMsg("");
  //         },
  //         (error) => {
  //           console.log(error.text);
  //         }
  //       );
  //   };

  return (
    <div className="contact-form" id="Contact">
      <div className="c-left">
        <div className="awesome">
          {/* <span className="c-span1">Get in Touch</span> <br /> */}
          <span className="c-span1">Get in Touch</span>
          <div
            className="blur s-blur1"
            style={{ background: "#ABF1FF94", marginTop: "110rem" }}
          ></div>
        </div>
      </div>

      <div className="c-right">
        <form
        // ref={form} onSubmit={sendEmail}
        >
          <input
            type="text"
            className="user"
            placeholder="Name"
            name="to_name"
            // value={val}
          />
          <input
            type="email"
            className="user"
            placeholder="E-mail"
            name="from_name"
            // value={mail}
          />
          <textarea
            name="message"
            className="user"
            placeholder="Message"
            // value={msg}
          ></textarea>
          <input type="submit" className="button sentBtn" value="Send" />
          {/* <span>{done && "Thanks for contacting Us!"}</span> */}
          <div
            className="blur c-blur1"
            style={{
              background: "var(--pur)",
            }}
          ></div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
