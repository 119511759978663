import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import "./Testimonial.css";

export default class Testimonial extends Component {
  render() {
    return (
      <div className="testimonial">
        <h1 className="testimonial-title">Testimonials</h1>
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={6100}
        >
          <div>
            <div className="myCarousel first-testimonial">
              <h3>Joanita Miranda MSW, LCSW</h3>
              <h4>Oncology Social Worker/Oncology Financial Navigator</h4>
              <p>
                To truly take care of the Whole (cancer ) patient we must
                alleviate the worry about the cost of care. Currently, there are
                almost no National foundations that assist with cost of
                Radiation while there are many that help with Chemotherapy. I am
                truly excited to work with BeingHumanmatters and their donations
                to help our patients worry a little less during their Cancer
                treatment and specifically their radiation bills.
              </p>
            </div>
          </div>

          <div>
            <div className="myCarousel">
              <h3>Frantz Decembre</h3>
              <h4>
                Benefitted from miscellaneous Beinghumanmatters.org services
              </h4>
              <p>
                Thank you for your assistance in everything you have done for
                me. My family and I are very grateful.
              </p>
            </div>
          </div>

          <div>
            <div className="myCarousel">
              <h3>House Manager</h3>
              <h4>72 Escher St, Trenton, NJ </h4>
              <p>
                Thank you for your volunteer house and donations to the Trenton
                Soup Kitchen.
              </p>
            </div>
          </div>
          <div>
            <div className="myCarousel">
              <h3>Julia Morgan</h3>
              <h4>Caregiver for a Cancer patient</h4>
              <p>
                Thank you for doing groceries for us and giving us rides to the
                train station.
              </p>
            </div>
          </div>
          <div>
            <div className="myCarousel">
              <h3>Dr. Stanley Krystek</h3>
              <h4>Immunology Scientist, Bristol-Myers Squibb</h4>
              <p>Thank you for giving back to the community.</p>
            </div>
          </div>
        </Carousel>
      </div>
    );
  }
}
