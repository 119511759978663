import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Mission.css";
const Mission = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="main" id="Team">
      <span>Our Mission</span>
      <span data-aos="zoom-in-up">
        Being Human Matters is a non-profit organization whose mission is to
        help patients prevail over Cancer. At Being Human, we believe in the
        power of humans coming together and collaborating with compassion on one
        platform to address some of the most challenging demands facing us in
        our fight against Cancer. Our longer term goal is to prevent, detect and
        treat Cancer through innovation and power of science. We pride ourselves
        in supporting and uplifting families dealing with uncertainty associated
        with Cancer.
        <div
          style={{ height: `${window.innerWidth >= 2000 ? "5vh" : "14vh"}` }}
        ></div>
      </span>
    </div>
  );
};

export default Mission;
