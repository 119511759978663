import React from "react";
import "./Cards.css";
import Card from "./Card";
function Cards() {
  return (
    <div className="cards">
      <div className="card-first-element first-card">
        <Card
          emoji={1}
          heading={"WHAT WE DO"}
          detail={
            "We are committed to providing financial and other forms of help to cancer patients and their families. We have partnered with the  Penn Medicine Princeton Medical Center - Oncology Social Worker and Oncology Financial Navigator to help patients with expenses so they can focus on their treatments with some relief on their bills and day-to-day expenses. One area of focus for our foundation is the un-met need for assistance with radiation bills."
          }
          path={"/whatwedo"}
        />
      </div>
      <div className="card-first-element second-cards">
        <Card
          emoji={2}
          heading={"Get in Touch"}
          detail={
            "If you are looking for help or want to help through volunteer work, please contact through the form below."
          }
          path={"/"}
        />
      </div>
    </div>
  );
}

export default Cards;
