import React from "react";
import "./Donateinfo.css";
function Donateinfo() {
  return (
    <div className="donation">
      <div className="main-container">
        <p className="main-container-heading">How To Make Donation</p>
        <span className="main-container-description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend
          nibh a quam elementum pulvinar. Sed vitae finibus nunc. Nunc ex risus,
          vulputate non felis vel, placerat malesuada nisl. Pellentesque at
          lacus posuere.
        </span>{" "}
        <br /> <br />
        <span className="main-container-description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend
          nibh a quam elementum pulvinar. Sed vitae finibus nunc. Nunc ex risus,
          vulputate non felis vel, placerat malesuada nisl. Pellentesque at
          lacus posuere, luctus dolor non, mollis risus. Aenean id vestibulum
        </span>
        <p className="main-container-donation">
          <strong>
            Donations by credit card or e-check can be made online:
          </strong>
        </p>
        <br />
        <button className="donate-btn">DONATE NOW</button>
      </div>
      <hr style={{ marginTop: "3rem", marginBottom: "3rem" }} />
      <div className="second-container">
        <div className="second-container-left">
          <div className="second-container-left-first">
            <span className="main-container-description">
              Lorem ipsum dolor sit amet, consectetur{" "}
            </span>
            <strong className="main-container-description">
              checks payable to “Being Human Matters”
            </strong>
            <span className="main-container-description">
              , to the following address:{" "}
            </span>
          </div>
          <p>
            <p
              className="main-container-description"
              style={{ fontWeight: "bolder" }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </p>
        </div>
        <div className="second-container-right">
          <span className="main-container-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            eleifend nibh a quam elementum pulvinar. Sed vitae finibus nunc.
            Nunc ex risus, vulputate non felis vel, placerat malesuada nisl.
            Pellentesque at lacus posuere, luctus dolor non, mollis risus.
            Aenean id vestibulum enim, eget eleifend enim. Maecenas et elementum
            tortor. Proin tristique est eu ligula tempus vehicula. Praesent eget
          </span>
          <br /> <br />
          <span className="main-container-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            eleifend nibh a quam elementum pulvinar. Sed vitae finibus nunc.
            Nunc ex risus, vulputate non felis vel, placerat malesuada nisl.
            Pellentesque at
          </span>
          <br /> <br />
          <span className="main-container-description">
            For more information, please contact us at {""}
          </span>
          <strong className="main-container-description">
            beinghumanmatters.org
          </strong>
          <br /> <br />
          <button className="donate-btn">DONATE NOW</button>
        </div>
      </div>
    </div>
  );
}

export default Donateinfo;
