import React from "react";
import "./Donateinfo.css";
function Whatwedo() {
  return (
    <div className="donation">
      <div className="main-container">
        <p className="main-container-heading">What We Do</p>
        <span className="main-container-description">
          At Being Human, we believe in the power of a community coming
          together, and collaborating to help individuals and their families
          fight one of the greatest challenges of their lifetime....the fight
          against cancer.
        </span>{" "}
        <br /> <br />
        <span className="main-container-description">
          Partnering with Penn Medicine Princeton Medical Center, we help
          patients with their unmetexpenses so they can focus on their
          treatments with some relief on their bills and day-to-day expenses.
          One area of focus for our foundation is the un-met patient assistance
          with radiation bills. Currently, there are almost no National
          Foundations that assist with cost of radiation while there are many
          that help with Chemotherapy" - Joanita Miranda MSW, LCSW Oncology
          Social Worker/Oncology Financial Navigator, Matthews Center for Cancer
          Care Penn Medicine Princeton
        </span>
        <p className="main-container-donation">
          <strong>We help in providing support in the following ways:</strong>
        </p>
        <ul>
          <li className="main-container-list">Financial Support </li>
          <li className="main-container-list">Help with Running errands,</li>
          <li className="main-container-list">
            Help with dropping/picking drop-off groceries{" "}
          </li>
          <li className="main-container-list">
            Pick-up & groups from appointments
          </li>
          <li className="main-container-list">
            Connecting to appropriate Support groups{" "}
          </li>
          <li className="main-container-list">
            Connect non-insured people help w/ accessing care through multiple
            channels through charitable giving and compassionate use programs
          </li>
        </ul>
        <span className="main-container-description">
          If you are looking for help to want to help, please get in touch with
          us by filling the online form in the "Get in touch" tab at the top of
          this page.
        </span>
      </div>
    </div>
  );
}

export default Whatwedo;
