import React from "react";
import "./Footer.css";
import facebook from "../images/Facebook.png";

const Footer = () => {
  return (
    <div className="footer">
      <p className="first">
        COPYRIGHT © 2023 BEING HUMAN MATTERS - ALL RIGHTS RESERVED.
      </p>
      <a
        href="https://www.facebook.com/people/Beinghumanmattersorg/100083649720205/"
        target="blank"
      >
        {" "}
        <img
          src={facebook}
          alt="facebook"
          style={{ height: "1.5rem", marginTop: "1rem" }}
        />{" "}
      </a>
    </div>
  );
};

export default Footer;
