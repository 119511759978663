import React from "react";
import Nav from "./Nav";
import Whatwedo from "./Whatwedo";
import Footer from "./Footer";

function Wedo() {
  return (
    <div className="App">
      <Nav />
      <Whatwedo />
      <Footer />
    </div>
  );
}

export default Wedo;
