import React, { useState } from "react";
import "./Story.css";
function Story() {
  const [style, setStyle] = useState("story-content-invisible");
  const [lmVal, setLmVal] = useState(true);
  const [stylebtn, setStylebtn] = useState("btn");
  const [isActive, setIsActive] = useState(true);
  const changeStyle = () => {
    setStyle("story-content-invisible-changed");
    setStylebtn("btn-style-changed");
    setIsActive((current) => !current);
    setLmVal(false);
  };
  return (
    <div
      className="story"
      id="Story"
      style={{
        height: isActive ? "fit-content" : "fit-content",
      }}
    >
      <h1 className="story_title">OUR STORY</h1>
      <p className="story_content">
        <span className="story-content-visible">
          We are high school freshman twins, Simrun and Runbier Sabhlok and this
          is our story...Our almost perfect lives were turned upside down when
          our mom was diagnosed with Stage IV AdenoCarcinoma or Non-Smoker Lung
          Cancer that had metasticized to other parts of her body. She needed to
          start treatments quickly to control the disease. Our mom is our best
          friend and we are very close to her, like most kids are age. And now
          suddenly we didn't know how much time our mom had with us! Seeing our
          mom in the ICU, we didn't know what our life was going to be. Our mom
          and us have a book club among us and many, many moons ago, we were
          reminded of how Lance Armstrong summed up his cancer fight “We have
          two options,: give up or fight like hell.” We geared up to fight
          drawing on all the collective strength! Since then our mom has been
          living through marathons of on-going chemotherapy treatments,
          immunotherapy treatments, lung surgery(Lobectomy), bone and brain
          metastasis, gamma knife radiation, and targeted mutation treatments.
          All these treatments are helping her to fight the disease and be with
          us longer. Our belief, to fall seven times and to get up eight times
          has kept us somehwhat going. Our lives now have cancer as a constant
          as our mom can never be cancer free but we take comfort that these
          treatments are helping her extend her life. <br />
          <br />
          In our fight against this invisible disease, we realized cancer is
          more than a disease, it challenges a family's mental, emotional, and
          financial well-being! It breaksdown everything in life that we take
          for granted. Another day with my mom is not granted anymore! It is an
          invisible disease where a family lives in constant fear.<br></br>
          <button className={stylebtn} onClick={changeStyle}>
            Learn More
          </button>
          <br />
          {lmVal ? (
            <>
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                Currently, we are forming our Board of Directors and will
                announce them soon.
              </span>
            </>
          ) : (
            ""
          )}
        </span>
        <span className={style}>
          As twins, we have always latched on each other for support, and this
          crisis has brought us so much closer. One of the many ways, we have
          learnt to manage our family stress is by focusing on what we can do
          for others and people like us in the community. During one such
          moments during our typical late-night conversations, reminiscing our
          carefree days, beinghumanmatters was born. It was brought from the
          realization that there are families like us struggling to survive and
          keep their families intact. <br />
          <br />
          We are a volunteer, nonprofit recognized by the Internal Revenue
          Service as a 501 (c) (3) organization focused on helping patients and
          families in their fight against this disease. We started with
          connecting volunteers with patient needs for grocery shopping for
          immuno-compromised patients during covid, providing free rides to/from
          appointments, etc. <br /> <br />
          As part of this charity we have discovered many unmet needs that we
          would like to help patients and their families with: <br />- Co-pays
          for radiation treatment bills as there are just a couple of chirites
          nationwide that help in this area <br /> - Support group(s) for young
          and teenage kids of cancer patients <br /> - Rent and bill payment
          assistance <br /> <br />
        </span>
        {!lmVal ? (
          <span style={{ fontWeight: "bold" }}>
            Currently, we are forming our Board of Directors and will announce
            them soon.
          </span>
        ) : (
          ""
        )}
      </p>
    </div>
  );
}

export default Story;
