import React, { useEffect, useState, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Counter.css";

const Counter = ({ counter }) => {
  console.log(counter);
  const [text1, setText1] = useState(0);
  const [text2, setText2] = useState(0);
  const [text3, setText3] = useState(0);
  const [text4, setText4] = useState(0);

  function animate(obj, initVal, lastVal, duration) {
    let startTime = null;
    //let currentTime = Date.now();
    const step = (currentTime = Date.now()) => {
      if (!startTime) {
        startTime = currentTime;
      }
      const progress = Math.min((currentTime - startTime) / duration, 1);
      if (obj === 1)
        setText1(Math.floor(progress * (lastVal - initVal) + initVal));
      if (obj === 2)
        setText2(Math.floor(progress * (lastVal - initVal) + initVal));
      if (obj === 3)
        setText3(Math.floor(progress * (lastVal - initVal) + initVal));
      if (obj === 4)
        setText4(Math.floor(progress * (lastVal - initVal) + initVal));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      } else {
        window.cancelAnimationFrame(window.requestAnimationFrame(step));
      }
    };
    window.requestAnimationFrame(step);
  }

  const load = useCallback(() => {
    animate(1, 0, counter.food, 5000);
    animate(2, 0, counter.patients, 5000);
    animate(3, 0, counter.ride, 5000);
    animate(4, 0, counter.volunteer, 5000);
  }, [counter.food, counter.patients, counter.ride, counter.volunteer]);

  useEffect(() => {
    AOS.init({ disable: "mobile" });
    load();
    // const timer = setInterval(() => {
    //   setText1((counter) => counter + 2);
    //   setText2((counter) => counter + 1);
    //   setText3((counter) => counter + 4);
    //   setText4((counter) => counter + 1);
    // }, 3000);
    // return () => clearInterval(timer);
  }, [load]);

  return (
    <div className="experience" id="Experiences" data-aos="zoom-in-up">
      <div
        className="achievement"
        data-aos="flip-down"
        data-aos-easing="ease-out-cubic"
        data-aos-delay="400"
      >
        <div className="circle" id="0101">
          {text1}
        </div>
        <span>Food Packets</span>
        <span>Delivered</span>
      </div>
      <div
        className="achievement"
        data-aos="flip-down"
        data-aos-easing="ease-out-cubic"
        data-aos-delay="800"
      >
        <div className="circle" id="0102">
          {text2}
        </div>
        <span>Patients</span>
        <span>Helped</span>
      </div>
      <div
        className="achievement"
        data-aos="flip-down"
        data-aos-easing="ease-out-cubic"
        data-aos-delay="1200"
      >
        <div className="circle" id="0103">
          {text3}
        </div>
        <span>Rides</span>
        <span>Provided</span>
      </div>
      <div
        className="achievement"
        data-aos="flip-down"
        data-aos-easing="ease-out-cubic"
        data-aos-delay="1200"
      >
        <div className="circle" id="0104">
          {text4 + "+"}
        </div>
        <span>Volunteer</span>
        <span>Hours</span>
      </div>
    </div>
  );
};

export default Counter;
