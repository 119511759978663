import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Donate from "./Components/Donate";
import Wedo from "./Components/Wedo";
// import Story from "./Components/Story";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/donate",
    element: <Donate />,
  },
  {
    path: "/whatwedo",
    element: <Wedo />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
