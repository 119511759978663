import React from "react";
import Nav from "./Nav";
import Donateinfo from "./Donateinfo";
import Footer from "./Footer";

function Donate() {
  return (
    <div className="App">
      <Nav />
      <Donateinfo />
      <Footer />
    </div>
  );
}

export default Donate;
