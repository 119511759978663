import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Nav.css";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import logo from "../images/logo.webp";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import { Link } from "react-scroll";
function Nav() {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  const clickHandler = () => {
    navigate("/donate", { replace: true });
  };

  const handleClick = () => {
    navigate("/whatwedo", { replace: true });
  };

  const onClickHandle = () => {
    navigate("/", { replace: true });
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ width: "500%" }}
    >
      <List>
        {["Home", "Mission", "Our Story", "Testimonials"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {[""].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div className="n-wrapper nav" id="Nav" data-aos="fade-down">
      <div className="n-left">
        <div className="n-left-first-container">
          <div className="n-name" onClick={onClickHandle}>
            Being Human Matters
          </div>
          <img src={logo} alt="logo" className="ngo_logo" />
        </div>
        <p className="sub-title">We help families fight Cancer!</p>
      </div>
      <div className="n-right">
        <div className="n-right-mobile-view">
          <React.Fragment>
            <Button
              onClick={toggleDrawer("menu", true)}
              style={{
                color: "black",
              }}
            >
              <DensityMediumIcon />
            </Button>
            <Drawer
              anchor={"menu"}
              open={state["menu"]}
              onClose={toggleDrawer("menu", false)}
            >
              {list("menu")}
            </Drawer>
          </React.Fragment>
        </div>
        <div className="n-list">
          <ul style={{ listStyleType: "none" }}>
            <li className="n-li" onClick={onClickHandle}>
              Home
            </li>
            <li className="n-li" onClick={handleClick}>
              What we do
            </li>
            <Link spy={true} to="Story" smooth={true}>
              <li className="n-li" onClick={onClickHandle}>
                Our Story
              </li>
            </Link>
            <Link spy={true} to="Contact" smooth={true}>
              <li className="n-li" onClick={onClickHandle}>
                Get in Touch
              </li>
            </Link>
          </ul>
        </div>
        {/* <Link spy={true} to="Donate" smooth={true}>
          <button className="button n-button" onClick={clickHandler}>
            Donate{" "}
          </button>
        </Link> */}
      </div>
      <div className="drawer"></div>
    </div>
  );
}

export default Nav;
