import React from "react";
import "./Card.css";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
const Card = ({ emoji, heading, detail, path }) => {
  // var navPath = path;
  const Navigate = useNavigate();
  const handleClick = () => {
    Navigate(path, { replace: true });
  };

  return (
    <div
      className={`card ${emoji===1 ? "first-card" : "second-card"}`}
      // style={{
      //   width:
      //     emoji === 1
      //       ? (window.innerWidth < 601
      //         ? "80%"
      //         : window.innerWidth >=601 && window.innerWidth <900 ? "82%" :
      //         "60%")
      //       : (window.innerWidth < 601
      //       ? "80%"
      //       : window.innerWidth >= 601 && window.innerWidth <900 ? "82%" :
      //         "161%"),
      //   marginLeft:
      //     emoji === 1
      //       ? (window.innerWidth < 601
      //         ? "0rem"
      //         : window.innerWidth >=601 && window.innerWidth <900 ? "-2rem" :
      //         window.innerWidth >=2100 ? "-25rem" : 
      //        "-12rem")
      //       : (window.innerWidth < 601
      //       ? "15rem"
      //       : window.innerWidth >=601 && window.innerWidth <900 ? "13rem" : 
      //       window.innerWidth >=2000 ? "-18rem" :
      //       "-7rem"),
      //   marginRight: emoji === 1 ? "0rem" : "15rem",
      // }}
    >
      {emoji === 1 ? (
        <ListAltIcon sx={{ fontSize: 80 }} />
      ) : emoji === 2 ? (
        <FavoriteIcon sx={{ fontSize: 80 }} />
      ) : (
        <FamilyRestroomIcon sx={{ fontSize: 80 }} />
      )}

      <span>{heading}</span>
      <span>{detail}</span>
      <Link spy={true} to="Contact" smooth={true}>
        <button className="c-button" onClick={handleClick}>
          Know more
        </button>
      </Link>
    </div>
  );
};

export default Card;
