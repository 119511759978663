import axios from "axios";
// eslint-disable-next-line
import CarouselMid from "./Components/CarouselMid";
import "./App.css";
import Counter from "./Components/Counter";
import Mission from "./Components/Mission";
import Nav from "./Components/Nav";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact";
import Cards from "./Components/Cards";
import Story from "./Components/Story";
import Testimonial from "./Components/Testimonial";
import ImageCarousel from "./Components/ImageCarousel";
import { useEffect, useState } from "react";

function App() {
  const [loader, setLoader] = useState(null);
  const [counter, setCounter] = useState({
    food: 0,
    patients: 0,
    ride: 0,
    volunteer: 0,
  });

  // useEffect(() => {
  //   setCounter({
  //     food: 212,
  //     patients: 108,
  //     ride: 162,
  //     volunteer: 86
  //   })
  //   //setLoader(1);
  // }, []);
  return (
    <div className="App">
      {loader ? (
        <>
          <Nav />
          <Counter counter={counter} />
          <Mission />
          <Cards />
          <Story />
          <Testimonial />
          <ImageCarousel />
          <Contact />
          <Footer />{" "}
        </>
      ) : (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      )}
    </div>
  );
}

export default App;
