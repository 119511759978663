import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import "./ImageCarousel.css";
import image from "../images/img1.JPG";

export default class ImageCarousel extends Component {
  render() {
    return (
      <div className="imageTestimonial">
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={6100}
          className="imageCarousel"
        >
          <div>
            <img src={image} className="imgCarousel" alt="img" />
          </div>

          <div></div>
        </Carousel>
      </div>
    );
  }
}
